import React, { useState } from 'react';
import axios from 'axios';

const DomainInput = () => {
  const [domain, setDomain] = useState('');
  const [response, setResponse] = useState('');
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleDomainChange = (e) => {
    const value = e.target.value;
    setDomain(value);
    setResponse(''); 
    setIsDisabled(value.trim() === ''); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`https://odc.officeapps.live.com/odc/v2.1/federationprovider?domain=${domain}`);
      const responseData = response.data;
      console.log('respos',response.data)
      // Check if the response contains an error message
      if (responseData.error) {
        setError("Couldn’t find your domain, make sure you entered it properly.");
        setResponse('');
      } else {
        const tenantId = responseData.tenantId;
        
        // Check if tenantId is empty
        if (!tenantId) {
          setError("Couldn’t find your domain, make sure you entered it properly.");
          
        } else {
          setResponse(tenantId);
          setError(null);
          setDomain('');
          setIsDisabled(true);
        }
      }
    } catch (error) {

    }
  };
  
  const handleCopyToClipboard = () => {
    if (response && response.trim() !== '') {
      navigator.clipboard.writeText(response);
      setCopied(true);
  
      // Reset the copied state after 3 seconds
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };
  
  
  
  return (
<div className='backgroundColor'>
<div className="backgroundColor">
<a className="navbar-brand" href="/#">
                            <img width={120} src="/logo.png" className="logo-one" alt="Logo" />
                        </a>
  <h1 className='text text-healine'>Get your Microsoft Entra tenant ID below</h1>
  {response ? (
    <p className='text'>Your Microsoft Entra tenant ID:</p>
  ) : (
    <p className='text'>Enter your primary domain name</p>
  )}
  <form onSubmit={handleSubmit} className="form container">
    <input
      type="text"
      value={response ? response : domain}
      onChange={handleDomainChange}
      placeholder={response ? response : "contoso.com"}
      className="input"
    />
      {error && (
      <div className="error">
        <p>{error}</p>
      </div>
    )}
   <button
            type="submit"
            className={`buttonn rounded-pill ${copied ? 'copied' : ''}`}
            onClick={handleCopyToClipboard}
          >
            {copied ? 'Copied' : (response ? 'Copy to Clipboard' : 'Get tenant ID')}
          </button>
  </form>

</div>
</div>

  );
};

export default DomainInput;

import logo from './logo.svg';
import './App.css';

import Home from '../src/componests/Home'
function App() {
  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;
